import React, {
    useCallback,
    useEffect,
    useState,
    useRef,
    useContext,
  } from "react";
  import {
    Autocomplete,
    List,
    ListItem,
    ListItemText,
    ListItemButton,
    CircularProgress,
    Input,
    Button,
  } from "@mui/material";
  import Caution from "../Tools/Caution";
  import RightArrowIcon from "../static/images/Rightarrow.svg";
  import { OverlayContext } from "../OverlayManager/Overlay";
  import { PatientpageContext } from "./../context/GlobalContext";
  import { debounce } from "lodash";
  import SendIcon from "@mui/icons-material/Send";
  import { OverlayType } from "../OverlayManager/Context";
  
  export default function PrescriptionInput1({
    data = [],
    label,
    fetchType = "",
    onSelect,
    placeholder,
    asyncData,
    feildsReset = "",
    icon,
    listQuery= true
  }) {
    const doctorContext = React.useContext(PatientpageContext);
    const [loader, setLoader] = useState(false);
    const [userQuery, setUserQuery] = useState(feildsReset[label] || "");
    const [inputQuery, setInputQuery] = useState(feildsReset[label] || "");
    
    // input query is a alternet  way of handling static input values like medication and medication_form and its only present in onchange event !
    const [staticUserQuery, setStaticUserQuery] = useState("");
    const [notFound, setNotFound] = useState(false);
    const prescriptionINput = useRef(null);
    const Overlay = useContext(OverlayContext);
    const isMedication = label === "medication" || label === "medication_form";
    const [searchResult, setSearchResult] = useState([]);
    useEffect(() => {
      setInputQuery(feildsReset[label]);
      if (fetchType === "medication/types") {
        setUserQuery(feildsReset[label]);
      }
    }, [feildsReset]);
  
    const updateQuery = () => {
      setLoader(true);
      doctorContext
        .prescriptionFetch(fetchType, userQuery)
        .then((res) => {
          setLoader(false);
          if (!res.length) {
            if (fetchType === "drugs_list") {
              addItems({ medicine_name: userQuery }, "medicine_name");
            }
          }
          setSearchResult(res);
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
          setSearchResult([]);
          setNotFound(true);
        });
    };
  
    const delayedQuery = useCallback(debounce(updateQuery, 500), [userQuery]);
    const onChange = (e) => {
      setUserQuery(e.target.value);
      setInputQuery(e.target.value);
    };
  
    const onClick = (e) => {
      setSearchResult(data);
    };
    useEffect(() => {
      window.addEventListener("click", (e) => {
        if (
          prescriptionINput.current &&
          prescriptionINput.current.contains(e.target)
        ) {
          return;
        }
        setSearchResult([]);
      });
      return () => {
        window.removeEventListener("click", (e) => {
          if (prescriptionINput.current.contains(e.target)) {
            return;
          }
          setSearchResult([]);
        });
      };
    }, []);
    useEffect(() => {
      if (asyncData &&listQuery && userQuery.length > 0) {
        delayedQuery();
      }
      return delayedQuery.cancel;
    }, [userQuery, delayedQuery, asyncData]);
  
    function addItems(item, key) {
      onSelect(item, key);
      setSearchResult([]);
      if (asyncData) {
        if (fetchType === "drugs_list") {
          if (
            item?.listed_side_effects &&
            Array.isArray(item?.listed_side_effects) &&
            item?.listed_side_effects.length
          ) {
            Overlay.show(OverlayType?.ModalWindow, {
              data: <Caution data={item} />,
              removeCloseButton: false,
            });
          }
          setInputQuery(item[key]);
        } else {
          setInputQuery("");
        }
        setUserQuery("");
      } else {
        setUserQuery(item[key]);
        // setInputQuery(item[key]);
      }
    }
  
    function enterCustomContent() {
      setNotFound(false);
      addItems(
        {
          definition: "",
          term: userQuery,
        },
        "term"
      );
      setUserQuery("");
      setInputQuery("");
    }
    /**
     * call this method onenter and send button
     */
    function manualEntry() {
      delayedQuery.cancel();
     (inputQuery.trim() || userQuery.trim()) && enterCustomContent();
    }
    function onKeyPress(e) {
      if (userQuery.trim() && e.key === "Enter") {
        manualEntry();
      }
    }
  
    const renderAutoCompleteList = (sr) => {
      switch (fetchType) {
        case "medication/types":
          return sr.map((item, index) => {
            return (
              <ListItem
                key={item.medication_form + index}
                className="autoCompleteList p-0"
                onClick={() => addItems(item, "medication_form")}
              >
                <ListItemButton className="py-0">
                  <ListItemText
                    primary={item.medication_form}
                    // secondary={item.typical_administration_route}
                    className="font-12"
                  ></ListItemText>
                </ListItemButton>
              </ListItem>
            );
          });
  
        case "drugs_list":
          return sr.map((item, index) => {
            return (
              <ListItem
                key={item.medicine_name + index}
                className="autoCompleteList p-0"
                onClick={() => addItems(item, "medicine_name")}
              >
                <ListItemButton className="py-0">
                  <ListItemText
                    primary={item.medicine_name}
                    secondary={item.drug_composition || ""}
                    className="font-12"
                  ></ListItemText>
                </ListItemButton>
              </ListItem>
            );
          });
  
        case "dose_timings":
          return sr.map((item, index) => {
            return (
              <ListItem
                key={item.term + index}
                className="autoCompleteList p-0"
                onClick={() => addItems(item, "term")}
              >
                <ListItemButton className="py-0">
                  <ListItemText
                    primary={item.term}
                    secondary={item.definition}
                    className="font-12"
                  ></ListItemText>
                </ListItemButton>
              </ListItem>
            );
          });
        default:
          return sr.map((item, index) => {
            if (item instanceof Object) {
              return (
                <ListItem
                  key={item.term + index}
                  className="autoCompleteList p-0"
                  onClick={() => addItems(item, "term")}
                >
                  <ListItemButton className="py-0">
                    <ListItemText
                      primary={item.term}
                      // secondary={item.definition}
                      className="font-12"
                    ></ListItemText>
                  </ListItemButton>
                </ListItem>
              );
            }
            return (
              <ListItem
                key={index}
                className="autoCompleteList p-0"
                onClick={() => addItems({ term: item }, "term")}
              >
                <ListItemButton className="py-0">
                  <ListItemText
                    primary={item}
                    // secondary={item.definition}
                    className="font-12"
                  ></ListItemText>
                </ListItemButton>
              </ListItem>
            );
          });
      }
    };
  
    return (
      <div className="pos-relative" ref={prescriptionINput}>
        <div className="pos-relative mb-2">
          {asyncData ? (
            <Input
              placeholder={placeholder}
              value={userQuery === "" ? inputQuery : userQuery}
              onChange={onChange}
              className="w-100 pr-3 font-12"
              autocomplete="off"
              autocorrect="off"
              onKeyPress={onKeyPress}
            />
          ) : (
            <div onClick={onClick}>
              <Input
                placeholder={placeholder}
                value={userQuery}
                // autocomplete="off"
                // autocorrect="off"
                style={{ pointerEvents: "none" }}
                className="w-100 pr-3 font-12"
                // onKeyPress={onKeyPress}
              />
            </div>
          )}
          {loader ? (
            <div className="autocompLeteLoader pos-absolute pointer-events">
              <CircularProgress size={15} />
            </div>
          ) : (
            <div
              className={`autocompLeteLoader pos-absolute ${isMedication ? 'pointer-events':''}`}
            >
              <img src={icon || RightArrowIcon} onClick={manualEntry} />
            </div>
          )}
          {/* {notFound && <div className="autocompleteSubmit pos-absolute"><Button variant="contained" size="small" onClick={enterCustomContent}>Click to Enter</Button></div>} */}
        </div>
        {Array.isArray(searchResult) && searchResult.length > 0 ? (
          <List
            dense
            className="autoSuggesionUl pos-absolute w-100 bg-white font-12"
          >
            {renderAutoCompleteList(searchResult)}
          </List>
        ) : searchResult?.records && Array.isArray(searchResult?.records) ? (
          <List
            dense
            className="autoSuggesionUl pos-absolute w-100 bg-white font-12"
          >
            {renderAutoCompleteList(searchResult?.records)}
          </List>
        ) : (
          <></>
        )}
      </div>
    );
  }